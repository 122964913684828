import {fetchSignInMethodsForEmail} from 'firebase/auth';
import {useMutation} from '@tanstack/react-query';
import {firebaseAuth} from '../auth/auth';
import {convertToJpFirebaseError} from '../services/convert-to-jp-firebase-error';
import {type UseFirebaseMutationOptions} from '../types/types';

export type FetchSignInMethodsForEmailRequest = {
  email: string;
};

export const useMutationSignInMethodsForEmail = (
  options?: UseFirebaseMutationOptions<
    string[],
    FetchSignInMethodsForEmailRequest
  >,
) => {
  return useMutation(
    async (parameters) => {
      return fetchSignInMethodsForEmail(firebaseAuth, parameters.email);
    },
    {
      ...options,
      onError(error, ...args) {
        const jpError = convertToJpFirebaseError(error);
        void options?.onError?.(jpError, ...args);
      },
    },
  );
};
