import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Button, Divider, Flex, SimpleGrid, Text, Link} from '@chakra-ui/react';
import {Card, CardContent, CardTitle} from 'dp-chakra/src/components/core/Card';
import {useDpErrorToast, useDpToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {FormEmailInput} from 'dp-chakra/src/components/core/FormEmailInput/FormEmailInput';
import {useSearchParams} from 'next/navigation';
import {AccountCardHeader} from '../AccountCard/AccountCardHeader';
import {SignInWithAppleButton} from '../SignInWithAppleButton/SignInWithAppleButton';
import {SignInWithGoogleButton} from '../SignInWithGoogleButton/SignInWithGoogleButton';
import {SignInWithLineButton} from '../SignInWithLineButton/SignInWithLineButton';
import {useMutationSignInMethodsForEmail} from '../../../packages/firebase-core/src/hooks/use-mutation-sign-in-methods-for-email';
import {useHandleRedirection} from '../../../packages/account-server/hooks/use-handle-redirection';
import {toCallbackUrl} from '../../../utils/callback-handler';

const authFieldIds = {
  email: 'email',
} as const;

type AuthFieldId = keyof typeof authFieldIds;

type AuthFiledValues = Record<AuthFieldId, string>;

export const AuthCard: React.FC = () => {
  const dpToast = useDpToast();
  const dpErrorToast = useDpErrorToast();
  const router = useRouter();
  const searchParameters = useSearchParams();
  const methods = useForm<AuthFiledValues>();

  const {isLoading: isHandleRedirectionLoading} = useHandleRedirection({
    onSignIn() {
      toCallbackUrl();
    },
    onUserCreate() {
      dpToast({
        title: 'Dr.’s Primeアカウントを作成しました',
      });
      void router.push({
        pathname: '/user-create',
        query: router.query,
      });
    },
    onError(error) {
      dpErrorToast({
        title: error.message,
      });
    },
  });

  const {
    mutate: fetchSignInMethodsForEmail,
    isLoading: isFetchSignInMethodsForEmailLoading,
  } = useMutationSignInMethodsForEmail({
    onError(error) {
      dpErrorToast({
        title: error.message,
      });
    },
  });

  const handleSubmit = (data: AuthFiledValues) => {
    fetchSignInMethodsForEmail(
      {
        email: data.email,
      },
      {
        onSuccess(providers) {
          if (providers.length > 0) {
            void router.push({
              pathname: '/signin',
              query: {
                ...router.query,
                email: data.email,
              },
            });
          } else {
            void router.push({
              pathname: '/signup',
              query: {
                ...router.query,
                email: data.email,
              },
            });
          }
        },
      },
    );
  };

  if (isHandleRedirectionLoading) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Card>
          <AccountCardHeader />
          <CardContent>
            <CardTitle>
              ログイン{' '}
              <Text as="span" fontWeight="normal">
                または
              </Text>{' '}
              新規登録
            </CardTitle>

            <SimpleGrid columns={1} spacing={4}>
              <SignInWithGoogleButton />

              <SignInWithAppleButton />

              <SignInWithLineButton />
            </SimpleGrid>

            <Flex marginY={4} alignItems="center">
              <Divider />
              <Text marginX={3} color="fontColor.sub" whiteSpace="nowrap">
                または
              </Text>
              <Divider />
            </Flex>

            <SimpleGrid column={1} spacing={4}>
              <FormEmailInput
                id={authFieldIds.email}
                options={{
                  required: true,
                }}
              />

              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isFetchSignInMethodsForEmailLoading}
              >
                続ける
              </Button>

              {/* パスワード再設定 */}
              <Link
                as={NextLink}
                href={`/password-reset?${searchParameters.toString()}`}
                color="fontColor.sub"
                fontSize="md"
                textAlign="right"
              >
                パスワードをお忘れの方
              </Link>
            </SimpleGrid>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};
